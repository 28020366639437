import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Container, Row, Col, Button, Navbar, Nav, Image } from 'react-bootstrap';
import { motion } from 'framer-motion';
import '../App.css';

const MainPage = () => {
  const [isDark, setIsDark] = useState(() => localStorage.getItem("dark") === "1");
  const navigate = useNavigate();

  useEffect(() => {
    document.body.classList.toggle("dark-mode", isDark);
  }, [isDark]);

  const toggleDarkMode = () => {
    setIsDark(!isDark);
    localStorage.setItem("dark", isDark ? "" : "1");
  };

  const handleStartMatching = () => {
    navigate('/sketch');
  };

  return (
    <div className={`App ${isDark ? 'dark-mode' : ''}`}>
      <Navbar bg="transparent" variant={isDark ? "dark" : "light"} expand="lg" className="py-3">
        <Container>
          <Navbar.Brand as={Link} to="/">
            <Image 
              src={isDark ? require("./pomu-logo-invert.png") : require("./pomu-logo.png")} 
              alt="Pomu Logo"
              fluid
              style={{ maxWidth: '60px' }}
            />
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="ms-auto">
              <Nav.Link onClick={toggleDarkMode} className="ms-2">
                {isDark ? '☀️' : '🌙'}
              </Nav.Link>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>

      <main className="d-flex align-items-center" style={{ minHeight: 'calc(100vh - 76px)' }}>
        <Container>
          <Row className="justify-content-center">
            <Col md={8} className="text-center">
              <motion.div
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.8 }}
              >
                <h1 className="display-4 fw-bold mb-4">
                  Find Top-Rated Manufacturers Tailored to Your Needs
                </h1>
                <p className="lead mb-5">
                  Pomu's AI matches fashion designers with ideal manufacturing partners. 
                  Streamline your production and bring designs to life effortlessly.
                </p>
                <motion.div
                  whileHover={{ scale: 1.05 }}
                  whileTap={{ scale: 0.95 }}
                >
                  <Button 
                    variant="outline-primary" 
                    size="lg"
                    onClick={handleStartMatching}
                    className="px-5 py-3"
                  >
                    Start Matching
                  </Button>
                </motion.div>
              </motion.div>
            </Col>
          </Row>
        </Container>
      </main>

      <footer className="py-3 text-center">
        <Container>
          <p className="mb-0">&copy; 2024 Pomu. All rights reserved.</p>
        </Container>
      </footer>
    </div>
  );
};

export default MainPage;