import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Container, Card, Button, ListGroup, Image, Navbar, Nav } from 'react-bootstrap';
import { Home, ArrowLeft } from 'lucide-react';

const ManufacturerResults = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const results = location.state?.results;
  const [isDark, setIsDark] = useState(() => localStorage.getItem("dark") === "1");

  useEffect(() => {
    document.body.classList.toggle("dark-mode", isDark);
  }, [isDark]);

  const toggleDarkMode = () => {
    setIsDark(!isDark);
    localStorage.setItem("dark", isDark ? "" : "1");
  };

  if (!results) {
    return (
      <Container className="py-5">
        <Card className={`shadow-lg border-0 ${isDark ? 'bg-dark text-white' : ''}`}>
          <Card.Body>
            <p>No results available. Please submit a tech pack first.</p>
            <Button variant="primary" onClick={() => navigate('/sketch')}>
              Go to Tech Pack Upload
            </Button>
          </Card.Body>
        </Card>
      </Container>
    );
  }

  const handleBack = () => {
    navigate('/sketch');
  };

  const handleHome = () => {
    navigate('/');
  };

  return (
    <div className={`App ${isDark ? 'dark-mode' : ''}`}>
      <Navbar bg="transparent" variant={isDark ? "dark" : "light"} expand="lg" className="py-3">
        <Container>
          <Navbar.Brand onClick={handleHome} style={{ cursor: 'pointer' }}>
            <Image
              src={isDark ? require("./pomu-logo-invert.png") : require("./pomu-logo.png")}
              alt="Pomu Logo"
              fluid
              style={{ maxWidth: '60px' }}
            />
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="ms-auto">
              <Nav.Link onClick={toggleDarkMode} className="ms-2">
                {isDark ? '☀️' : '🌙'}
              </Nav.Link>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
      <Container className="py-5">
        <Card className={`shadow-lg border-0 ${isDark ? 'bg-dark text-white' : ''}`}>
          <Card.Header className={`${isDark ? 'bg-primary' : 'bg-primary'} text-white py-3`}>
            <div className="d-flex justify-content-between align-items-center">
              <Button variant="link" className="text-white p-0" onClick={handleBack}>
                <ArrowLeft size={24} />
              </Button>
              <h2 className="mb-0 fs-4">Manufacturer Results</h2>
              <Button variant="link" className="text-white p-0" onClick={handleHome}>
                <Home size={24} />
              </Button>
            </div>
          </Card.Header>
          <Card.Body className={isDark ? 'bg-dark' : ''}>
            <ListGroup variant="flush">
              {Object.entries(results).map(([key, value]) => (
                <ListGroup.Item key={key} className={`mb-3 ${isDark ? 'bg-dark text-white border-secondary' : ''}`}>
                  <h5>{key.replace(/_/g, ' ').toUpperCase()}</h5>
                  <div className="d-flex align-items-center">
                    <Image
                      src={`data:image/jpeg;base64,${value.base64_encoding}`}
                      alt={`${value.manufacturer} sample`}
                      style={{ width: '100px', height: '100px', objectFit: 'cover' }}
                      className="me-3"
                    />
                    <div>
                      <p className="mb-1"><strong>Manufacturer:</strong> {value.manufacturer}</p>
                      <p className="mb-0"><strong>Similarity Score:</strong> {value.similarity.toFixed(4)}</p>
                    </div>
                  </div>
                </ListGroup.Item>
              ))}
            </ListGroup>
          </Card.Body>
        </Card>
      </Container>
    </div>
  );
};

export default ManufacturerResults;