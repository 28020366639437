import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Container from 'react-bootstrap/Container';
import Card from 'react-bootstrap/Card';
import Alert from 'react-bootstrap/Alert';
import Spinner from 'react-bootstrap/Spinner';
import { Image as BootstrapImage, Navbar, Nav } from 'react-bootstrap';
import { Upload, ArrowLeft } from 'lucide-react';
import APIService from './APIService';
import Pica from 'pica';

const LoadingOverlay = () => (
  <div style={{
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: 1000,
  }}>
    <div className="text-center">
      <Spinner animation="border" variant="light" />
      <p className="mt-2 text-white">Processing your request...</p>
    </div>
  </div>
);

const SketchForm = () => {
  const navigate = useNavigate();
  const [clipFile, setClipFile] = useState(null);
  const [clipFileBase64, setClipFileBase64] = useState(null);
  const [prompt, setPrompt] = useState('');
  const [submitStatus, setSubmitStatus] = useState({ message: '', type: '' });
  const [isLoading, setIsLoading] = useState(false);
  const [isDark, setIsDark] = useState(() => localStorage.getItem("dark") === "1");

  useEffect(() => {
    document.body.classList.toggle("dark-mode", isDark);
  }, [isDark]);

  const toggleDarkMode = () => {
    setIsDark(!isDark);
    localStorage.setItem("dark", isDark ? "" : "1");
  };

  const handleGoBack = () => {
    navigate('/');
  };

  const handleClipFileChange = async (e) => {
    const selectedFile = e.target.files[0];
    if (selectedFile && selectedFile.type === 'image/jpeg') {
      try {
        console.log('Starting image processing');
        const resizedImage = await resizeImage(selectedFile);
        console.log('Image resized successfully');
        const base64Image = await convertToBase64(resizedImage);
        console.log('Image converted to base64 successfully');
        setClipFile(selectedFile);
        setClipFileBase64(base64Image);
        setSubmitStatus({ message: '', type: '' });
      } catch (error) {
        console.error('Error processing image:', error);
        console.error('Error stack:', error.stack);
        setSubmitStatus({ 
          message: `Error processing image: ${error.message}. Please try again.`, 
          type: 'danger' 
        });
      }
    } else {
      setClipFile(null);
      setClipFileBase64(null);
      setSubmitStatus({ message: 'Please select a JPEG file for the design model.', type: 'danger' });
    }
  };

  const handlePromptChange = (e) => {
    setPrompt(e.target.value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setSubmitStatus({ message: '', type: '' });
    setIsLoading(true);
  
    if (!clipFile || !clipFileBase64) {
      setSubmitStatus({ message: 'Please select a design model file to upload.', type: 'danger' });
      setIsLoading(false);
      return;
    }
  
    if (!prompt.trim()) {
      setSubmitStatus({ message: 'Please enter a prompt.', type: 'danger' });
      setIsLoading(false);
      return;
    }
  
    const submitData = new FormData();
    submitData.append('image', clipFileBase64);
    submitData.append('prompt', prompt);
    submitData.append('userEmail', 'demo@example.com');
  
    try {
      const response = await APIService.SubmitTechPack(submitData);
      console.log('Server response:', response);
      setIsLoading(false);
      navigate('/manufacturer-results', { state: { results: response } });
    } catch (error) {
      console.error('Failed to submit tech pack:', error);
      setSubmitStatus({ message: 'Failed to submit. Please try again.', type: 'danger' });
      setIsLoading(false);
    }
  };

  const renderClipFileUpload = () => (
    <Form.Group className="mb-4">
      <Form.Label className="fw-bold">Upload Design Model</Form.Label>
      <div className={`d-flex justify-content-center align-items-center rounded p-4 ${isDark ? 'bg-secondary' : 'bg-light'}`} style={{ cursor: 'pointer', border: '2px dashed #ced4da' }}>
        <label htmlFor="clip-file-upload" className="mb-0" style={{ cursor: 'pointer' }}>
          {clipFile ? (
            <BootstrapImage src={URL.createObjectURL(clipFile)} alt="Preview" fluid rounded style={{ maxHeight: '200px' }} />
          ) : (
            <div className="text-center">
              <Upload size={48} className="text-primary mb-2" />
              <p className={`${isDark ? 'text-light' : 'text-muted'}`}>Click to upload or drag and drop</p>
            </div>
          )}
        </label>
        <Form.Control
          id="clip-file-upload"
          type="file"
          accept=".jpg,.jpeg"
          onChange={handleClipFileChange}
          className="d-none"
        />
      </div>
    </Form.Group>
  );

  return (
    <div className={`App ${isDark ? 'dark-mode' : ''}`}>
      <Navbar bg="transparent" variant={isDark ? "dark" : "light"} expand="lg" className="py-3">
        <Container>
          <Navbar.Brand onClick={handleGoBack} style={{ cursor: 'pointer' }}>
            <BootstrapImage
              src={isDark ? require("./pomu-logo-invert.png") : require("./pomu-logo.png")}
              alt="Pomu Logo"
              fluid
              style={{ maxWidth: '60px' }}
            />
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="ms-auto">
              <Nav.Link onClick={toggleDarkMode} className="ms-2">
                {isDark ? '☀️' : '🌙'}
              </Nav.Link>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
      <Container className="py-5" style={{ maxWidth: '700px' }}>
        {isLoading && <LoadingOverlay />}
        <Card className={`shadow-lg border-0 ${isDark ? 'bg-dark text-white' : ''}`}>
          <Card.Header className={`${isDark ? 'bg-primary' : 'bg-primary'} text-white py-3`}>
            <div className="d-flex justify-content-between align-items-center">
              <Button variant="link" className="text-white p-0" onClick={handleGoBack}>
                <ArrowLeft size={24} />
              </Button>
              <h2 className="mb-0 fs-4">Tech Pack Upload</h2>
              <div style={{ width: '24px' }}></div>
            </div>
          </Card.Header>
          <Card.Body className={`px-4 py-5 ${isDark ? 'bg-dark' : ''}`}>
            {submitStatus.message && (
              <Alert variant={submitStatus.type} className="mb-4">
                {submitStatus.message}
              </Alert>
            )}
            <Form onSubmit={handleSubmit}>
              {renderClipFileUpload()}
              <Form.Group className="mb-4">
                <Form.Label className="fw-bold">Enter Prompt</Form.Label>
                <Form.Control
                  as="textarea"
                  rows={3}
                  placeholder="Enter your prompt here"
                  value={prompt}
                  onChange={handlePromptChange}
                  required
                  style={{
                    resize: 'vertical',
                    minHeight: '100px',
                    maxHeight: '300px',
                    overflowY: 'auto'
                  }}
                  className={isDark ? 'bg-dark text-white' : ''}
                />
              </Form.Group>
              <Button 
                variant="primary" 
                type="submit" 
                className="w-100 mt-3 py-2" 
                disabled={!clipFileBase64 || !prompt.trim() || isLoading}
              >
                {isLoading ? 'Submitting...' : 'Submit Tech Pack'}
              </Button>
            </Form>
          </Card.Body>
        </Card>
      </Container>
    </div>
  );
};

const resizeImage = async (file) => {
  const pica = new Pica();
  
  return new Promise((resolve, reject) => {
    const img = new window.Image();
    img.onload = async () => {
      try {
        const canvas = document.createElement('canvas');
        canvas.width = 224;
        canvas.height = 224;

        await pica.resize(img, canvas, {
          filter: 'lanczos3',
          alpha: true,
        });
        resolve(canvas);
      } catch (error) {
        reject(error);
      }
    };
    img.onerror = () => reject(new Error('Failed to load image'));
    img.src = URL.createObjectURL(file);
  });
};

const convertToBase64 = (canvas) => {
  return new Promise((resolve, reject) => {
    try {
      canvas.toBlob((blob) => {
        if (!blob) {
          reject(new Error('Failed to create blob from canvas'));
          return;
        }
        const reader = new FileReader();
        reader.onloadend = () => resolve(reader.result.split(',')[1]);
        reader.onerror = (e) => reject(new Error(`FileReader error: ${e.target.error}`));
        reader.readAsDataURL(blob);
      }, 'image/jpeg');
    } catch (error) {
      reject(error);
    }
  });
};

export default SketchForm;