// APIService.js

import axios from 'axios';

const BASE_URL = "https://prodapi.pomu.io";  // aws instance url

const APIService = {
  async SubmitUserRequest(formData) {
    try {
      const response = await axios.post(`${BASE_URL}/api/submit-user-request/`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      return response.data;
    } catch (error) {
      console.error('Error submitting form:', error);
      throw error;
    }
  },

  async SubmitTechPack(formData) {
    try {
      const response = await axios.post(`${BASE_URL}/api/submit-tech-pack/`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      return response.data;
    } catch (error) {
      console.error('Error submitting tech pack:', error);
      throw error;
    }
  }
};

export default APIService;
