import React from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';

import SketchForm from './components/SketchForm';
import MainPage from './components/Home';
import ManufacturerResults from './components/ManufacturerResults';

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<MainPage />} />
        <Route path="/home" element={<Navigate to="/" />} />
        <Route path="/sketch" element={<SketchForm />} />
        <Route path="/manufacturer-results" element={<ManufacturerResults />} />
        <Route path="*" element={<Navigate to="/" />} />
      </Routes>
    </Router>
  );
}

export default App;